var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-complete-user",staticClass:"complete-modal",attrs:{"title":'Perfil incompleto',"no-close-on-backdrop":"","centered":"","size":_vm.showForm ? 'xl' : 'md',"hide-footer":""},on:{"hide":_vm.onHide},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('div',{staticClass:"d-flex flex-column align-items-center"},[(!_vm.showForm)?_c('p',{staticClass:"text-center my-1"},[_vm._v(" Algunos campos obligatorios de tu perfil no han sido completados. Por favor, revisa la información y completa los campos faltantes. ")]):_vm._e(),(!_vm.showForm)?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){_vm.showForm = true}}},[_vm._v("Completar perfil")]):_vm._e(),(_vm.showForm)?_c('validation-observer',{ref:"form",staticClass:"w-100"},[_c('b-form',{staticClass:"w-100"},[_c('b-row',{staticClass:"w-100"},[_c('b-col',{attrs:{"lg":"7"}},[_c('b-card',[_c('h2',{staticClass:"mb-2"},[_vm._v("Información personal")]),_c('b-row',[_c('b-col',{attrs:{"md":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Tipo de documento","label-for":"document"}},[_c('v-select',{staticClass:"w-100",attrs:{"id":"document","options":_vm.configurations.document_types,"clearable":false,"label":"name","disabled":true,"reduce":function (option) { return option.id; }},model:{value:(_vm.userObject.document_type_id),callback:function ($$v) {_vm.$set(_vm.userObject, "document_type_id", $$v)},expression:"userObject.document_type_id"}})],1)],1),_c('b-col',{attrs:{"md":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label-for":"dni"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("DNI")]),_c('span',{staticClass:"required-asterisk"},[_vm._v("(*)")])]},proxy:true}],null,false,178197557)},[_c('validation-provider',{attrs:{"rules":"required|dni"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('#########'),expression:"'#########'"}],class:errors[0]
														? 'is-invalid'
														: !errors[0] && _vm.userObject.document_number
														? 'is-valid'
														: '',attrs:{"id":"dni","maxLength":8,"disabled":_vm.$route.params.user_id &&
													_vm.userObject.first_name &&
													_vm.userObject.last_name
														? true
														: false,"placeholder":'Ingresar número de DNI...'},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getClientByDni()}},model:{value:(_vm.userObject.document_number),callback:function ($$v) {_vm.$set(_vm.userObject, "document_number", $$v)},expression:"userObject.document_number"}}),_c('span',{staticClass:"error-span"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1411896696)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label-for":"first_name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Nombre")]),_c('span',{staticClass:"required-asterisk"},[_vm._v("(*)")])]},proxy:true}],null,false,657383343)},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
														var errors = ref.errors;
														var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"first_name","state":errors[0] ? false : valid ? true : null,"maxLength":50,"disabled":true,"placeholder":'Ingresar un nombre...'},model:{value:(_vm.userObject.first_name),callback:function ($$v) {_vm.$set(_vm.userObject, "first_name", $$v)},expression:"userObject.first_name"}}),_c('span',{staticClass:"error-span"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2915001126)})],1)],1),_c('b-col',{attrs:{"md":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Segundo nombre","label-for":"middle_name"}},[_c('b-form-input',{attrs:{"id":"middle_name","maxLength":50,"disabled":true,"placeholder":'Ingresar un nombre...'},model:{value:(_vm.userObject.middle_name),callback:function ($$v) {_vm.$set(_vm.userObject, "middle_name", $$v)},expression:"userObject.middle_name"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label-for":"last_name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Apellido")]),_c('span',{staticClass:"required-asterisk"},[_vm._v("(*)")])]},proxy:true}],null,false,594295264)},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
														var errors = ref.errors;
														var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"last_name","state":errors[0] ? false : valid ? true : null,"maxLength":50,"disabled":true,"placeholder":'Ingresar un apellido...'},model:{value:(_vm.userObject.last_name),callback:function ($$v) {_vm.$set(_vm.userObject, "last_name", $$v)},expression:"userObject.last_name"}}),_c('span',{staticClass:"error-span"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3012784681)})],1)],1),_c('b-col',{attrs:{"md":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label-for":"second_last_name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Segundo apellido")]),_c('span',{staticClass:"required-asterisk"},[_vm._v("(*)")])]},proxy:true}],null,false,2115305249)},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
														var errors = ref.errors;
														var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"second_last_name","state":errors[0] ? false : valid ? true : null,"maxLength":50,"disabled":true,"placeholder":'Ingresar un apellido...'},model:{value:(_vm.userObject.second_last_name),callback:function ($$v) {_vm.$set(_vm.userObject, "second_last_name", $$v)},expression:"userObject.second_last_name"}}),_c('span',{staticClass:"error-span"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,528351369)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label-for":"dob"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Fecha de nacimiento")]),_c('span',{staticClass:"required-asterisk"},[_vm._v("(*)")])]},proxy:true}],null,false,2000573263)},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
														var errors = ref.errors;
														var valid = ref.valid;
return [_c('b-form-datepicker',{attrs:{"id":"dob","locale":"en-US","placeholder":"Seleccionar...","date-format-options":{
													month: 'numeric',
													day: 'numeric',
													year: 'numeric',
												},"max":_vm.maxDate},model:{value:(_vm.userObject.dob),callback:function ($$v) {_vm.$set(_vm.userObject, "dob", $$v)},expression:"userObject.dob"}}),_c('span',{staticClass:"error-span"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1395703358)})],1)],1),_c('b-col',{attrs:{"md":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label-for":"country_id"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("País de nacimiento")]),_c('span',{staticClass:"required-asterisk"},[_vm._v("(*)")])]},proxy:true}],null,false,1211994985)},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
												var errors = ref.errors;
												var valid = ref.valid;
return [_c('v-select',{staticClass:"w-100",attrs:{"id":"country_id","options":_vm.configurations.countries,"clearable":false,"label":"name","reduce":function (option) { return option.id; },"placeholder":'Seleccionar una nacionalidad...'},model:{value:(_vm.userObject.country_id),callback:function ($$v) {_vm.$set(_vm.userObject, "country_id", $$v)},expression:"userObject.country_id"}}),_c('span',{staticClass:"error-span"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3860713562)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"gender"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Género")]),_c('span',{staticClass:"required-asterisk"},[_vm._v("(*)")])]},proxy:true}],null,false,1858211662)},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
												var errors = ref.errors;
												var valid = ref.valid;
return [_c('b-form-radio-group',{attrs:{"id":"gender","options":_vm.configurations.genders,"value-field":"id","text-field":"name","disabled-field":"notEnabled"},model:{value:(_vm.userObject.gender_id),callback:function ($$v) {_vm.$set(_vm.userObject, "gender_id", $$v)},expression:"userObject.gender_id"}}),_c('span',{staticClass:"error-span"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4091397276)})],1)],1)],1)],1)],1),_c('b-col',{attrs:{"lg":"5"}},[_c('b-card',[_c('h2',{staticClass:"mb-2"},[_vm._v("Información laboral")]),_c('b-row',[_c('b-col',{attrs:{"md":"12","lg":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label-for":"date_joined"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Fecha de ingreso a la empresa")]),_c('span',{staticClass:"required-asterisk"},[_vm._v("(*)")])]},proxy:true}],null,false,3345684932)},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
												var errors = ref.errors;
												var valid = ref.valid;
return [_c('b-form-datepicker',{attrs:{"id":"date_joined","locale":"en-US","placeholder":"Seleccionar...","date-format-options":{
													month: 'numeric',
													day: 'numeric',
													year: 'numeric',
												}},model:{value:(_vm.userObject.date_joined),callback:function ($$v) {_vm.$set(_vm.userObject, "date_joined", $$v)},expression:"userObject.date_joined"}}),_c('span',{staticClass:"error-span"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,460278044)})],1)],1),_c('b-col',{attrs:{"md":"12","lg":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label-for":"salary"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Salario (S/)")]),_c('span',{staticClass:"required-asterisk"},[_vm._v("(*)")])]},proxy:true}],null,false,159324832)},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
												var errors = ref.errors;
												var valid = ref.valid;
return [_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"salary"},model:{value:(_vm.userObject.salary),callback:function ($$v) {_vm.$set(_vm.userObject, "salary", $$v)},expression:"userObject.salary"}},'money',{
													decimal: '.',
													thousands: ',',
													prefix: 'S/ ',
													precision: 2,
													masked: false,
												},false)),_c('span',{staticClass:"error-span"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2397259351)})],1)],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"12","lg":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Fecha de pago mensual","label-for":"pay_day"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"flex-start","gap":"0.35rem","white-space":"nowrap"}},[_c('span',[_vm._v("Todos los")]),_c('v-select',{staticStyle:{"width":"90px"},attrs:{"id":"pay_day","options":_vm.days,"clearable":false,"label":"name","disabled":true},model:{value:(_vm.pay_day),callback:function ($$v) {_vm.pay_day=$$v},expression:"pay_day"}}),_c('span',[_vm._v("de cada mes.")])],1)])],1)],1)],1),_c('b-card',[_c('h2',{staticClass:"mb-2"},[_vm._v("Información de contacto")]),_c('b-row',[_c('b-col',{attrs:{"xl":"8"}},[_c('b-form-group',{attrs:{"label-for":"address"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Dirección")]),_c('span',{staticClass:"required-asterisk"},[_vm._v("(*)")])]},proxy:true}],null,false,669883512)},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
												var errors = ref.errors;
												var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"address","state":errors[0] ? false : valid ? true : null,"maxLength":50,"placeholder":'Ingresar una dirección...'},model:{value:(_vm.userObject.address),callback:function ($$v) {_vm.$set(_vm.userObject, "address", $$v)},expression:"userObject.address"}}),_c('span',{staticClass:"error-span"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2486127424)})],1)],1),_c('b-col',{attrs:{"xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"phone_number"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Teléfono")]),_c('span',{staticClass:"required-asterisk"},[_vm._v("(*)")])]},proxy:true}],null,false,3954336298)},[_c('validation-provider',{attrs:{"rules":"tel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
												var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('#########'),expression:"'#########'"}],class:errors[0]
														? 'is-invalid'
														: !errors[0] && _vm.userObject.phone_number
														? 'is-valid'
														: '',attrs:{"id":"phone_number","maxLength":50,"placeholder":'Ingresar un número de teléfono...'},model:{value:(_vm.userObject.phone_number),callback:function ($$v) {_vm.$set(_vm.userObject, "phone_number", $$v)},expression:"userObject.phone_number"}}),_c('span',{staticClass:"error-span"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3077674142)})],1)],1)],1)],1)],1)],1),_c('b-row',{staticClass:"w-100"},[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-card',[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('h2',{staticClass:"mb-2"},[_vm._v("Información bancaria")]),_c('b-button',{staticClass:"btn btn-icon btn-primary",attrs:{"variant":"success"},on:{"click":_vm.addRow}},[_c('feather-icon',{staticClass:"text-white cursor-pointer",attrs:{"icon":"PlusIcon","size":"15"}})],1)],1),_vm._l((_vm.userObject.bank_accounts),function(bank,index){return _c('b-row',{key:index},[_c('b-col',{attrs:{"xl":"3"}},[_c('b-form-group',{attrs:{"label-for":"bank"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Banco")]),_c('span',{staticClass:"required-asterisk"},[_vm._v("(*)")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
														var errors = ref.errors;
														var valid = ref.valid;
return [_c('v-select',{staticClass:"w-100",attrs:{"id":"bank","options":_vm.configurations.banks,"clearable":false,"label":"name","reduce":function (option) { return option.id; },"placeholder":'Seleccionar una entidad bancaria...'},model:{value:(bank.bank_id),callback:function ($$v) {_vm.$set(bank, "bank_id", $$v)},expression:"bank.bank_id"}}),_c('span',{staticClass:"error-span"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"xl":"4"}},[_c('b-form-group',{attrs:{"label":"Numero de cuenta bancaria","label-for":"account_number"},scopedSlots:_vm._u([(bank.bank_id === 1)?{key:"label",fn:function(){return [_c('span',[_vm._v("Numero de cuenta bancaria")]),_c('span',{staticClass:"required-asterisk"},[_vm._v("(*)")])]},proxy:true}:null],null,true)},[_c('validation-provider',{attrs:{"rules":bank.bank_id !== 1 ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
														var errors = ref.errors;
														var valid = ref.valid;
return [_c('b-form-input',{attrs:{"type":"number","id":"account_number","maxLength":50,"placeholder":'Ingresar cuenta bancaria...'},model:{value:(bank.account_number),callback:function ($$v) {_vm.$set(bank, "account_number", $$v)},expression:"bank.account_number"}}),_c('span',{staticClass:"error-span"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"xl":"4"}},[_c('b-form-group',{attrs:{"label":"CCI","label-for":"cci"},scopedSlots:_vm._u([(bank.bank_id !== 1)?{key:"label",fn:function(){return [_c('span',[_vm._v("CCI")]),_c('span',{staticClass:"required-asterisk"},[_vm._v("(*)")])]},proxy:true}:null],null,true)},[_c('validation-provider',{attrs:{"rules":bank.bank_id === 1 ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
														var errors = ref.errors;
														var valid = ref.valid;
return [_c('b-form-input',{attrs:{"type":"number","id":"cci","maxLength":10,"placeholder":'Ingresar cuenta interbancaria...'},model:{value:(bank.cci),callback:function ($$v) {_vm.$set(bank, "cci", $$v)},expression:"bank.cci"}}),_c('span',{staticClass:"error-span"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"xl":"1"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end h-100",class:_vm.userObject.bank_accounts.length === 1 ? 'disabled-btn' : ''},[_c('b-button',{staticClass:"btn btn-icon btn-primary",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.deleteRow(index)}}},[_c('feather-icon',{staticClass:"text-white cursor-pointer",attrs:{"size":"15","icon":"XIcon"}})],1)],1)])],1)})],2)],1)],1)],1)],1):_vm._e(),_c('div',{staticClass:"d-flex align-items-center justify-content-center w-100"},[(_vm.showForm)?_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("Guardar")]):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
	<div id="app" class="h-100" :class="[skinClasses]" v-loading.full="loading">
		<component :is="layout">
			<router-view :key="$route.fullPath" />
		</component>
		<b-modal hide-footer hide-header v-model="notificationIsOpen" centered body-class="p-0" no-close-on-backdrop>
			<notification-lote
				:notifications="notifications"
				@closeNotificationModal="notificationIsOpen = false"
			></notification-lote>
		</b-modal>
		<scroll-to-top v-if="enableScrollToTop" />
		<Note v-if="activeNote.show" />

		<EggsFoundModal
			:show="eggsFound.sidebar.show"
			@closing="
				SET_VALUES_EGGS_FOUND({
					eggsFound: {
						...eggsFound,
						sidebar: {
							show: false,
						},
					},
				})
			"
		/>
		<TasksSidebar :show="showTasks" @closing="showTasks = false" />

		<CompleteUserModal v-if="isFieldsCompleted === 0" />
		<BirthdayModal v-if="isOpenBirthday" @close="showBirthdayModal = false" />
		<AdminBirthdayModal v-if="isAdmin && currentUser.notification_alerts.length > 0" />
	</div>
</template>

<script>
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig"
import ScrollToTop from "@core/components/scroll-to-top/ScrollToTop.vue"
import { provideToast } from "vue-toastification/composition"
import { watch } from "@vue/composition-api"
import useAppConfig from "@core/app-config/useAppConfig"
import { useWindowSize, useCssVar } from "@vueuse/core"
import EggsFoundModal from "./views/amg/header-bookmarks/eggs-found/EggsFoundModal.vue"
import { mapState, mapMutations } from "vuex"
import CompleteUserModal from "@/views/amg/header-bookmarks/CompleteUserModal.vue"
import BirthdayModal from "@/views/amg/header-bookmarks/BirthdayModal.vue"
import AdminBirthdayModal from "@/views/amg/header-bookmarks/AdminBirthdayModal.vue"

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue")
const LayoutBrain = () => import("@/layouts/brain/LayoutBrain.vue")
const LayoutHorizontal = () => import("@/layouts/horizontal/LayoutHorizontal.vue")
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue")

import store from "@/store"

import Note from "@/views/amg/header-bookmarks/sticky-notes/Note.vue"
import useStickyNotes from "@/views/amg/header-bookmarks/sticky-notes/useStickyNotes"

import TasksSidebar from "@/views/amg/header-bookmarks/tasks/TasksSidebar.vue"
import useTasks from "@/views/amg/header-bookmarks/tasks/useTasks.js"

export default {
	name: "App",
	props: {
		currentuser: [Object, String],
	},
	components: {
		LayoutHorizontal,
		LayoutVertical,
		LayoutFull,
		LayoutBrain,
		ScrollToTop,
		// Custom
		Note,
		EggsFoundModal,
		TasksSidebar,
		CompleteUserModal,
		BirthdayModal,
		AdminBirthdayModal,
	},
	computed: {
		currentRoute() {
			return this.$route.name
		},
		layout() {
			if (this.$route.meta.layout === "full") return "layout-full"
			if (this.$route.meta.layout === "horizontal") return "layout-horizontal"
			if (this.$route.meta.layout === "brain") return "layout-brain"
			return `layout-${this.contentLayoutType}`
		},
		contentLayoutType() {
			return this.$store.state.appConfig.layout.type
		},
		loading() {
			return this.$store.getters["app/loading"]
		},
		...mapState("headers", ["eggsFound"]),
		isOpenBirthday() {
			if (this.$store.state.auth && this.$store.state.auth.birthday && this.$store.state.auth.birthday == true) {
				return true
			}
			return false
		},
	},
	data() {
		return {
			notificationIsOpen: false,
			notifications: [],
			showBirthdayModal: false,
		}
	},
	async created() {
		await store.dispatch("video/INIT_PLAYRATES")
		if (this.currentUser.notification != null) {
			this.showBirthdayModal = true
			store.commit("auth/SET_BIRTHDAY", true)
		}
	},
	methods: {
		...mapMutations("headers", ["SET_VALUES_EGGS_FOUND"]),
		closeBirthdat() {
			this.showBirthdayModal = false
			store.commit("auth/SET_BIRTHDAY", false)
		},
	},
	beforeCreate() {
		// Set colors in theme
		const colors = ["primary", "secondary", "success", "info", "warning", "danger", "light", "dark"]

		// eslint-disable-next-line no-plusplus
		for (let i = 0, len = colors.length; i < len; i++) {
			$themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
		}

		// Set Theme Breakpoints
		const breakpoints = ["xs", "sm", "md", "lg", "xl"]

		// eslint-disable-next-line no-plusplus
		for (let i = 0, len = breakpoints.length; i < len; i++) {
			$themeBreakpoints[breakpoints[i]] = Number(
				useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2)
			)
		}

		// Set RTL
		const { isRTL } = $themeConfig.layout
		document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr")
	},
	setup() {
		const { skin, skinClasses } = useAppConfig()
		const { enableScrollToTop } = $themeConfig.layout

		if (skin.value === "dark") document.body.classList.add("dark-layout")

		provideToast({
			hideProgressBar: true,
			closeOnClick: false,
			closeButton: false,
			icon: false,
			timeout: 3000,
			transition: "Vue-Toastification__fade",
		})

		// Set Window Width in store
		store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth)

		const { width: windowWidth } = useWindowSize()

		watch(windowWidth, (val) => {
			store.commit("app/UPDATE_WINDOW_WIDTH", val)
		})
		watch(skin, (theme) => document.documentElement.setAttribute("data-theme", theme), {
			immediate: true,
		})

		// Notes
		const { activeNote } = useStickyNotes()

		// Tasks
		const { showTasks } = useTasks()

		return {
			skinClasses,
			enableScrollToTop,
			activeNote,
			showTasks,
		}
	},
}
</script>

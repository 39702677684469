<template>
	<b-modal
		ref="modal-birthday"
		:title="`Feliz cumpleaños, ${currentUser.name}!`"
		no-close-on-backdrop
		centered
		v-model="isActive"
		@hidden="handleHidden"
		size="md"
		hide-footer
	>
		<div class="d-flex flex-column align-items-center">
			<p class="text-center">{{ currentUser.notification ? currentUser.notification.message : "" }}</p>
			<b-button variant="primary" @click="viewed">No volver a mostrar</b-button>
		</div>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal"
import store from "@/store"
import commonsService from "@/services/commons.service"

export default {
	components: {},
	mixins: [modalMixin],
	props: {
		show: Boolean,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
		}
	},
	async created() {},
	computed: {},
	methods: {
		handleHidden() {
			// this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},
		async viewed() {
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Revisar notificaciones`,
				text: `Se marcaran todas las notificaciones como revisado.`,
			})
			if (!isConfirmed) return
			this.isPreloading()
			try {
				const res = await commonsService.viewedBirthday()
				this.showSuccessToast("Las notificaciones fueron actualizados correctamente.")

				let userData = JSON.parse(localStorage.getItem("vuex"))
				userData.user.notification = null
				localStorage.setItem("vuex", JSON.stringify(userData))
				store.commit("auth/SET_CURRENT_USER", userData)
				// this.isActive = false
				this.$emit("closing")
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},

	async mounted() {
		this.toggleModal("modal-birthday")
	},
}
</script>

<style></style>

import Vue from "vue"

const url = "/api"

class CommonsService {
	async getCargos() {
		try {
			return await Vue.axios.get(`${url}/get-privileges`)
		} catch (err) {
			console.error(err)
		}
	}
	async getGeneralSettings() {
		try {
			const data = await axios.get(`${url}/brain/others/settings-generals/`)
			return data
		} catch (error) {
			throw error
		}
	}
	async editSettings({ name, value_text, value_number }) {
		try {
			const data = await axios.put(`${url}/brain/others/settings-generals/${name}`, { value_text, value_number })
			return data
		} catch (error) {
			throw error
		}
	}
	async viewedAdmin() {
		try {
			const data = await axios.post(`${url}/users/check-notification-alert`)
			return data
		} catch (error) {
			throw error
		}
	}

	async viewedBirthday() {
		try {
			const data = await axios.post(`${url}/users/check-notification`)
			return data
		} catch (error) {
			throw error
		}
	}
	async getFemaleChicks() {
		try {
			return await Vue.axios.get(`${url}/get-female-chicks-for-depuration`)
		} catch (err) {
			console.error(err)
		}
	}
	async getFemaleChicksDetail(corral_id) {
		try {
			return await Vue.axios.get(`${url}/get-female-chicks-depuration-detail`, { params: { corral_id } })
		} catch (err) {
			console.error(err)
		}
	}
	async getDepurationCounter(corral_id) {
		try {
			return await Vue.axios.get(`${url}/get-counter-chicks-depuration`)
		} catch (err) {
			console.error(err)
		}
	}
}
export default new CommonsService()

import Vue from "vue"

import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue"
Vue.component(FeatherIcon.name, FeatherIcon)

import Filters from "./components/commons/Filters.vue"
Vue.component("filters-component", Filters)

import DragAndDropComponent from "./components/commons/drag-and-drop/DragAndDropComponent.vue"
Vue.component("drag-and-drop-component", DragAndDropComponent)

import CustomLightBox from "./components/commons/cool-light-box/components/CoolLightBox.vue"
Vue.component("custom-light-box", CustomLightBox)

import SpecimenCard from "./components/commons/SpecimenCard.vue"
Vue.component("specimen-card", SpecimenCard)

import VueTree from "@ssthouse/vue-tree-chart"
Vue.component("vue-tree", VueTree)

import SmartIcon from "./icons/SmartIcon.vue"
Vue.component(SmartIcon.name, SmartIcon)

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import {
	faUserSecret,
	faTableList,
	faBars,
	faSkull,
	faEgg,
	faHandshake,
	faArrowUpLong,
	faArrowDownLong,
} from "@fortawesome/free-solid-svg-icons"
import { faStickyNote, faThumbsDown, faThumbsUp, faClock } from "@fortawesome/free-regular-svg-icons"
// import {  } from "@fortawesome/fontawesome-svg-core";
// import { IconName } from "@fortawesome/free-regular-svg-icons";
library.add(
	faUserSecret,
	faTableList,
	faBars,
	faSkull,
	faEgg,
	faStickyNote,
	faThumbsDown,
	faThumbsUp,
	faClock,
	faArrowUpLong,
	faArrowDownLong
)

Vue.component("font-awesome-icon", FontAwesomeIcon)

import flatpickr from "flatpickr"
import VueFlatPickr from "vue-flatpickr-component"
import "flatpickr/dist/flatpickr.css"
import { Spanish } from "flatpickr/dist/l10n/es.js"
flatpickr.localize(Spanish)
VueFlatPickr.props.config.default({
	altInput: true,
	altFormat: "F j, Y",
	dateFormat: "Y-m-d",
})
Vue.component("flat-pickr", VueFlatPickr)

import vSelect from "vue-select"
Vue.component("v-select", vSelect)

import VueNumberInput from "@chenfengyuan/vue-number-input"
Vue.component("vue-number-input", VueNumberInput)

import { ValidationProvider, ValidationObserver } from "vee-validate"
Vue.component("ValidationProvider", ValidationProvider)
Vue.component("ValidationObserver", ValidationObserver)

// OH Vue icons
import { OhVueIcon, addIcons } from "oh-vue-icons"
import {
	LaUserClockSolid,
	FaCamera,
	HiClock,
	RiCloseCircleFill,
	BiArrowRightCircleFill,
	BiArrowLeftCircleFill,
	GiBroom,
	FaSkull,
	FaSkullCrossbones,
	GiBoxingGlove,
	GiTransform,
	SiReactivex,
	GiDroplets,
	GiEyedropper,
	GiRooster,
	FaTrophy,
	FaHandshake,
	FaCross,
	MdHealthandsafetyOutlined,
	BiXCircleFill,
	BiCheckCircleFill,
	BiCircleFill,
	LaUserNurseSolid,
	FaWeight,
	MdAddphotoalternate,
	FaAward,
} from "oh-vue-icons/icons"

addIcons(
	LaUserClockSolid,
	FaCamera,
	HiClock,
	RiCloseCircleFill,
	BiArrowRightCircleFill,
	BiArrowLeftCircleFill,
	GiBroom,
	FaSkull,
	FaSkullCrossbones,
	GiBoxingGlove,
	GiTransform,
	SiReactivex,
	GiDroplets,
	GiEyedropper,
	GiRooster,
	FaTrophy,
	FaCross,
	MdHealthandsafetyOutlined,
	BiXCircleFill,
	BiCheckCircleFill,
	BiCircleFill,
	LaUserNurseSolid,
	FaWeight,
	MdAddphotoalternate,
	FaHandshake,
	FaAward
)

Vue.component("v-icon", OhVueIcon)

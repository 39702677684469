<template>
	<b-modal
		ref="modal-admin-birthday"
		:title="'Notificación de próximos cumpleaños'"
		no-close-on-backdrop
		centered
		v-model="isActive"
		@hidden="handleHidden"
		size="xsmd"
		hide-footer
	>
		<div class="d-flex flex-column align-items-center">
			<b-table-simple responsive sticky-header="60vh" no-border-collapse>
				<b-thead class="sticky-header" style="white-space: nowrap">
					<b-th class="text-center">Mensaje</b-th>
					<b-th class="text-center">Cumpleaños</b-th>
					<b-th class="text-center">Usuario</b-th>
					<b-th class="text-center">Fecha de notificación</b-th>
				</b-thead>
				<b-tbody>
					<template
						v-if="currentUser.notification_alerts.length > 0"
						v-for="(item, index) in currentUser.notification_alerts"
					>
						<b-tr :key="index">
							<b-td class="text-center">
								{{ item.message }}
							</b-td>
							<b-td class="text-center">
								{{ JSON.parse(item.parameters).dob | myGlobalDayShort }}
							</b-td>
							<b-td class="text-center">
								{{ JSON.parse(item.parameters).first_name }} {{ JSON.parse(item.parameters).last_name }}
							</b-td>
							<b-td class="text-center">{{ item.created_at | myGlobalDayShort }}</b-td>
						</b-tr>
					</template>
				</b-tbody>
			</b-table-simple>
			<div class="d-flex align-items-center justify-content-end">
				<b-button variant="primary" @click="viewed">Marcar como revisados</b-button>
			</div>
		</div>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal"
import commonsService from "@/services/commons.service"
import store from "@/store"

export default {
	components: {},
	mixins: [modalMixin],
	props: {
		show: Boolean,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
		}
	},
	async created() {},
	computed: {},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},
		async viewed() {
			console.log("visto")
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Revisar notificaciones`,
				text: `Se marcaran todas las notificaciones como revisado.`,
			})
			if (!isConfirmed) return
			this.isPreloading()
			try {
				const res = await commonsService.viewedAdmin()
				this.showSuccessToast("Las notificaciones fueron actualizados correctamente.")

				let userData = JSON.parse(localStorage.getItem("vuex"))
				userData.user.notification_alerts = []
				localStorage.setItem("vuex", JSON.stringify(userData))
				store.commit("auth/SET_CURRENT_USER", userData)
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},

	async mounted() {
		this.toggleModal("modal-admin-birthday")
	},
}
</script>

<style></style>

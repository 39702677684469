import moment from "moment"
import axios from "axios"

import specimenService from "@/services/specimens.service"
import videosService from "@/services/videos.service"
import rivalsService from "@/services/rivals.service"
import chicksService from "@/services/chicks.service"
import configVideoService from "@/services/configVideoService"

const createObject = (category_idIn = 1, type_idIn = 1) => ({
	category_id: category_idIn,
	type_id: type_idIn,
	specimen_id: null,
	specimen_two_id: null,
	chick_id: null,
	chick_two_id: null,
	rival_id: null,
	participant_id: null,
	result_id: null,
	tournament_stage_specimen_id: null,
	order: null,
	date: moment().format("YYYY-MM-DD"),
	amarrador_id: null,
	careador_id: null,
	description: null,
	uid: null,
	oldUid: null,
	thumbnail: null,
	user_id: null,
	id: null,
	tournament_id: null,
	id_fight_results: null,
	local_reason_id: null,
	rival_reason_id: null,
})

const createFile = () => ({
	name: null,
	data: null,
	type: null,
	base: null,
	progress: 0,
	uploadFinished: false,
	extension: null,
})
//A_UPDATE_VIDEO_TOPES

/* STATES */
const state = {
	videos: [],
	uploadVideo: createObject(),

	optionsCategory: [
		{ item: 1, name: "TOPE" },
		{ item: 2, name: "PELEA" },
		// { item: 3, name: "TORNEO" },
	],
	optionsType: [
		{ item: 1, name: "INTERNO" },
		{ item: 2, name: "EXTERNO" },
	],
	optionsResultado: [
		{ item: 1, name: "TRIUNFO" },
		{ item: 2, name: "EMPATE" },
		{ item: 3, name: "DERROTA" },
	],
	optionsSpecimen: [],
	optionsSpecimenRival: [],
	optionsRival: [],
	optionsParticipant: [],
	arrayAmarradores: [],
	arrayCareadores: [],
	arrayGallos: [],
	file: createFile(),
	arrayTournaments: [],
	arrayFightResults: [],
	uploading: false,
	playbackRates: [],
}

/* GETTERS */
const getters = {
	optionsCategory: (state) => (is_specimen) => {
		return state.optionsCategory.filter((p) => {
			if (!is_specimen) {
				return p.item == 1
			}
			return p
		})
	},
}

/* MUTATIONS */
const mutations = {
	M_RESET_FORM_VIDEO(state, { category_idIn, type_idIn }) {
		state.uploadVideo = createObject(category_idIn, type_idIn)
		state.file = createFile()
		state.uploading = false
	},
	M_DELETE_FILE_VIDEO(state) {
		state.file = createFile()
	},
	M_SET_ARRAY_AMARADOR(state, data) {
		state.arrayAmarradores = data
	},
	M_SET_ARRAY_CAREADOR(state, data) {
		state.arrayCareadores = data
	},
	M_SET_DATE_RESULT_NOW(state, data) {
		state.uploadVideo.date = data
	},
	M_SET_SPECIMENS(state, data) {
		if (data.length > 0) {
			state.optionsSpecimen = data.map((item) => {
				return {
					...item,
					label: item.correlative
						? item.correlative
						: `${item.plate} ${item.alias ? "/ " + item.alias : ""} `,
					value: item.id,
				}
			})
		} else {
			state.optionsSpecimen = []
			state.uploadVideo.specimen_id = null
			state.uploadVideo.chick_id = null
		}
	},
	M_SET_SPECIMENS_RIVAL(state, data) {
		if (data.length > 0) {
			state.optionsSpecimenRival = data.map((item) => {
				return {
					...item,
					label: item.correlative
						? item.correlative
						: `${item.plate} ${item.alias ? "/ " + item.alias : ""} `,
					value: item.id,
				}
			})
		} else {
			state.optionsSpecimenRival = []
			state.uploadVideo.specimen_two_id = null
			state.uploadVideo.chick_two_id = null
			state.uploadVideo.rival_id = null
			state.uploadVideo.participant_id = null
		}
	},
	M_SET_OPTIONS_RIVALS(state, data) {
		if (data.length > 0) {
			state.optionsRival = data.map((item) => {
				return {
					...item,
					label: item.correlative
						? item.correlative
						: `${item.plate} ${item.alias ? "/ " + item.alias : ""} `,
					value: item.id,
				}
			})
		} else {
			state.optionsRival = []
			state.uploadVideo.rival_id = null
		}
	},
	M_SET_INPUT(state, { data, input }) {
		state.uploadVideo[input] = data
	},
	M_DATA_FILE(state, { name, data, type, base, progress, uploadFinished, extension }) {
		state.file = {
			name,
			data,
			type,
			base,
			progress,
			uploadFinished,
			extension,
		}
	},
	M_SET_TOURNAMENTS(state, data) {
		state.arrayTournaments = data
	},
	M_SET_FIGHT_RESULTS(state, data) {
		state.arrayFightResults = data
	},
	M_SET_VIDEOS(state, data) {
		state.videos = data
	},
	M_SET_VIDEO_BY_ID(state, { id, data }) {
		state.videos = state.videos.map((item) => {
			if (parseInt(item.id) === parseInt(id)) {
				return data
			}
			return item
		})
	},
	M_TOGGLE_UPLOADING() {
		state.uploading = !state.uploading
	},
	M_SET_PLAYBACKRATES(state, data) {
		state.playbackRates = data
		// Guardar velocidad en el LocalStorage
		const object = { speed: data.find((item) => parseFloat(item.speed_default)).speed }
		localStorage.setItem("plyr", JSON.stringify(object))
	},
}

/* ACTIONS */
const actions = {
	async A_GET_SPECIMENS({ commit, state }, { search }) {
		const { data } = await specimenService.getSpecimensToFight(search)
		return data
	},
	async A_GET_CHIKS_BY_CORR({ commit, state }, { search }) {
		const { data } = await specimenService.getChikcsByName(search)
		return data
	},
	async A_UPLOAD_VIDEO_NODE({ commit, state }, videoForm) {
		commit("M_TOGGLE_UPLOADING")
		const { data } = await axios.post(`${process.env.VUE_APP_URL_NODE}/api/v1/cloudflare/get-upload`, videoForm)
		const {
			msg,
			ok,
			resp: {
				result: { uid, thumbnail },
			},
		} = data

		commit("M_TOGGLE_UPLOADING")

		return {
			msg,
			ok,
			uid,
			thumbnail,
		}
	},
	async A_SAVE_VIDEO({ commit, state }, { videoForm, config }) {
		commit("M_TOGGLE_UPLOADING")
		await videosService.saveVideoTope({ videoForm, config })
		commit("M_TOGGLE_UPLOADING")
	},
	A_RESET_UPLOAD_VIDEO({ commit, state }) {
		commit("M_RESET_VIDEO")
	},
	async A_SEARCH_EJEMPLAR({ dispatch, commit }, { search, loading, type, side }) {
		loading(true)
		let data = []
		if (search.length > 1) {
			if (type == "specimen") {
				data = await dispatch("A_GET_SPECIMENS", { search })
			} else if (type == "chick") {
				data = await dispatch("A_GET_CHIKS_BY_CORR", { search })
			}

			if (side == "local") {
				commit("M_SET_SPECIMENS", data)
			} else if (side == "visit") {
				commit("M_SET_SPECIMENS_RIVAL", data)
			}
			// commit("M_SET_INPUT", { data: null, input: "specimen_id" });
			// commit("M_SET_INPUT", { data: null, input: "specimen_two_id" });
		}
		loading(false)
	},

	// : _.debounce(async function  {

	// }, 500),
	async A_GET_TOURNAMENTS({ commit }) {
		const { status, data } = await videosService.getTournaments()
		if (status == 200) {
			commit("M_SET_TOURNAMENTS", data)
		}
	},
	async A_GET_FIGHT_RESULTS_BY_TOURNAMENT_ID({ commit }, { tournament_id }) {
		console.log({ tournament_id })
		const { status, data } = await videosService.getFightResultsByTournamentId({ tournament_id })
		if (status == 200) {
			commit("M_SET_FIGHT_RESULTS", data)
		}
	},
	async A_SAVE_VIDEO_TOURNAMENT({ commit }, { videoForm, config }) {
		commit("M_TOGGLE_UPLOADING")
		await videosService.saveVideoTournament({ videoForm, config })
		commit("M_TOGGLE_UPLOADING")
	},
	async A_GET_PLATE_SPECIMEN_BY_ID({ commit, dispatch }, { specimen_id }) {
		const { status, data } = await specimenService.getIdToUpdateVideo({ specimen_id })
		if (status == 200) {
			const { id, alias, plate } = data.specimen
			const spe = await dispatch("A_GET_SPECIMENS", { search: plate })
			commit("M_SET_SPECIMENS_RIVAL", spe)
			// console.log('object', spe);
		}
	},
	async A_GET_CORR_CHICK_BY_ID({ commit, dispatch }, { chick_id }) {
		const { status, data } = await chicksService.getChickById({ chick_id })
		if (status == 200) {
			const { correlative } = data.chick
			const resp = await dispatch("A_GET_CHIKS_BY_CORR", { search: correlative })
			commit("M_SET_SPECIMENS_RIVAL", resp)
		}
	},
	async A_UPDATE_VIDEO_TOPES({ commit }, { videoForm, config, id }) {
		commit("M_TOGGLE_UPLOADING")
		const resp = await videosService.updateDateVideoTope({ videoForm, config, id })
		commit("M_TOGGLE_UPLOADING")
		return resp
	},
	async A_UPDATE_VIDEO_TOURNAMENT({ commit }, { videoForm, config }) {
		commit("M_TOGGLE_UPLOADING")
		const resp = await videosService.updateVideoTournament({ videoForm, config })
		commit("M_TOGGLE_UPLOADING")
		return resp
	},
	async A_GET_RIVALS({ commit, state }) {
		const data = await rivalsService.getRivals()
		commit("M_SET_OPTIONS_RIVALS", data)
	},
	async A_GET_ARRAY_AMARRADOR({ commit, state }, body) {
		const data = await videosService.getAmarrador()
		commit("M_SET_ARRAY_AMARADOR", data)
	},
	async A_GET_ARRAY_CAREADOR({ commit, state }, body) {
		const data = await videosService.getCareador()
		commit("M_SET_ARRAY_CAREADOR", data)
	},
	async INIT_PLAYRATES({ commit, state }) {
		const { data } = await configVideoService.index(null)
		this.speeds = data.map((it) => ({
			...it,
			check: it.check == 1 ? true : false,
			speed_default: it.speed_default == 1 ? true : false,
		}))
		commit("M_SET_PLAYBACKRATES", data)
	},
}
export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}

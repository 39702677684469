export const SET_CURRENT_USER = (state, data) => {
	if (data) {
		const { user, token } = data
		state.currentUser = user
		state.token = token
	} else {
		state.currentUser = null
		state.token = ""
	}
}

export const SET_BIRTHDAY = (state, data) => {
    console.log("update-birt: ", data)
	state.birthday = data
}

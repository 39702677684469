<template>
	<b-modal
		ref="modal-complete-user"
		:title="'Perfil incompleto'"
		no-close-on-backdrop
		@hide="onHide"
		centered
		v-model="isActive"
		:size="showForm ? 'xl' : 'md'"
		hide-footer
		class="complete-modal"
	>
		<div class="d-flex flex-column align-items-center">
			<p class="text-center my-1" v-if="!showForm">
				Algunos campos obligatorios de tu perfil no han sido completados. Por favor, revisa la información y
				completa los campos faltantes.
			</p>

			<b-button variant="primary" v-if="!showForm" @click="showForm = true">Completar perfil</b-button>

			<validation-observer ref="form" class="w-100" v-if="showForm">
				<b-form class="w-100">
					<b-row class="w-100">
						<b-col lg="7">
							<b-card>
								<h2 class="mb-2">Información personal</h2>
								<b-row>
									<b-col md="12" lg="6">
										<b-form-group label="Tipo de documento" label-for="document">
											<v-select
												id="document"
												v-model="userObject.document_type_id"
												:options="configurations.document_types"
												:clearable="false"
												class="w-100"
												label="name"
												:disabled="true"
												:reduce="(option) => option.id"
											/>
										</b-form-group>
									</b-col>

									<b-col md="12" lg="6">
										<b-form-group label-for="dni">
											<template #label>
												<span>DNI</span>
												<span class="required-asterisk">(*)</span>
											</template>
											<validation-provider rules="required|dni" v-slot="{ errors }">
												<b-form-input
													id="dni"
													v-model="userObject.document_number"
													v-mask="'#########'"
													:class="
														errors[0]
															? 'is-invalid'
															: !errors[0] && userObject.document_number
															? 'is-valid'
															: ''
													"
													:maxLength="8"
													:disabled="
														$route.params.user_id &&
														userObject.first_name &&
														userObject.last_name
															? true
															: false
													"
													@keyup.enter="getClientByDni()"
													:placeholder="'Ingresar número de DNI...'"
												/>
												<span class="error-span">{{ errors[0] }}</span>
											</validation-provider>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col md="12" lg="6">
										<b-form-group label-for="first_name">
											<template #label>
												<span>Nombre</span>
												<span class="required-asterisk">(*)</span>
											</template>
											<validation-provider rules="required" v-slot="{ errors, valid }">
												<b-form-input
													id="first_name"
													v-model="userObject.first_name"
													:state="errors[0] ? false : valid ? true : null"
													:maxLength="50"
													:disabled="true"
													:placeholder="'Ingresar un nombre...'"
												/>
												<span class="error-span">{{ errors[0] }}</span>
											</validation-provider>
										</b-form-group>
									</b-col>

									<b-col md="12" lg="6">
										<b-form-group label="Segundo nombre" label-for="middle_name">
											<b-form-input
												id="middle_name"
												v-model="userObject.middle_name"
												:maxLength="50"
												:disabled="true"
												:placeholder="'Ingresar un nombre...'"
											/>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row>
									<b-col md="12" lg="6">
										<b-form-group label-for="last_name">
											<template #label>
												<span>Apellido</span>
												<span class="required-asterisk">(*)</span>
											</template>
											<validation-provider rules="required" v-slot="{ errors, valid }">
												<b-form-input
													id="last_name"
													v-model="userObject.last_name"
													:state="errors[0] ? false : valid ? true : null"
													:maxLength="50"
													:disabled="true"
													:placeholder="'Ingresar un apellido...'"
												/>
												<span class="error-span">{{ errors[0] }}</span>
											</validation-provider>
										</b-form-group>
									</b-col>

									<b-col md="12" lg="6">
										<b-form-group label-for="second_last_name">
											<template #label>
												<span>Segundo apellido</span>
												<span class="required-asterisk">(*)</span>
											</template>
											<validation-provider rules="required" v-slot="{ errors, valid }">
												<b-form-input
													id="second_last_name"
													v-model="userObject.second_last_name"
													:state="errors[0] ? false : valid ? true : null"
													:maxLength="50"
													:disabled="true"
													:placeholder="'Ingresar un apellido...'"
												/>
												<span class="error-span">{{ errors[0] }}</span>
											</validation-provider>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row>
									<b-col md="12" lg="6">
										<b-form-group label-for="dob">
											<template #label>
												<span>Fecha de nacimiento</span>
												<span class="required-asterisk">(*)</span>
											</template>
											<validation-provider rules="required" v-slot="{ errors, valid }">
												<b-form-datepicker
													id="dob"
													class=""
													locale="en-US"
													placeholder="Seleccionar..."
													:date-format-options="{
														month: 'numeric',
														day: 'numeric',
														year: 'numeric',
													}"
													v-model="userObject.dob"
													:max="maxDate"
												/>
												<span class="error-span">{{ errors[0] }}</span>
											</validation-provider>
										</b-form-group>
									</b-col>
									<b-col md="12" lg="6">
										<b-form-group label-for="country_id">
											<template #label>
												<span>País de nacimiento</span>
												<span class="required-asterisk">(*)</span>
											</template>
											<validation-provider rules="required" v-slot="{ errors, valid }">
												<v-select
													id="country_id"
													v-model="userObject.country_id"
													:options="configurations.countries"
													:clearable="false"
													class="w-100"
													label="name"
													:reduce="(option) => option.id"
													:placeholder="'Seleccionar una nacionalidad...'"
												/>
												<span class="error-span">{{ errors[0] }}</span>
											</validation-provider>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row>
									<b-col cols="12" md="12">
										<b-form-group label-for="gender">
											<template #label>
												<span>Género</span>
												<span class="required-asterisk">(*)</span>
											</template>
											<validation-provider rules="required" v-slot="{ errors, valid }">
												<b-form-radio-group
													id="gender"
													v-model="userObject.gender_id"
													:options="configurations.genders"
													class=""
													value-field="id"
													text-field="name"
													disabled-field="notEnabled"
												></b-form-radio-group>
												<span class="error-span">{{ errors[0] }}</span>
											</validation-provider>
										</b-form-group>
									</b-col>
								</b-row>
							</b-card>
						</b-col>
						<b-col lg="5">
							<b-card>
								<h2 class="mb-2">Información laboral</h2>

								<b-row>
									<b-col md="12" lg="12" xl="6">
										<b-form-group label-for="date_joined">
											<template #label>
												<span>Fecha de ingreso a la empresa</span>
												<span class="required-asterisk">(*)</span>
											</template>
											<validation-provider rules="required" v-slot="{ errors, valid }">
												<b-form-datepicker
													id="date_joined"
													class=""
													locale="en-US"
													placeholder="Seleccionar..."
													:date-format-options="{
														month: 'numeric',
														day: 'numeric',
														year: 'numeric',
													}"
													v-model="userObject.date_joined"
												/>
												<span class="error-span">{{ errors[0] }}</span>
											</validation-provider>
										</b-form-group>
									</b-col>

									<b-col md="12" lg="12" xl="6">
										<b-form-group label-for="salary">
											<template #label>
												<span>Salario (S/)</span>
												<span class="required-asterisk">(*)</span>
											</template>
											<validation-provider rules="required" v-slot="{ errors, valid }">
												<money
													id="salary"
													v-model="userObject.salary"
													v-bind="{
														decimal: '.',
														thousands: ',',
														prefix: 'S/ ',
														precision: 2,
														masked: false,
													}"
													class="form-control"
												></money>
												<span class="error-span">{{ errors[0] }}</span>
											</validation-provider>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row class="mb-2">
									<b-col md="12" lg="12" xl="6">
										<b-form-group label="Fecha de pago mensual" label-for="pay_day">
											<div
												style="
													display: flex;
													align-items: center;
													justify-content: flex-start;
													gap: 0.35rem;
													white-space: nowrap;
												"
											>
												<span>Todos los</span>
												<v-select
													id="pay_day"
													v-model="pay_day"
													:options="days"
													:clearable="false"
													label="name"
													:disabled="true"
													style="width: 90px"
												/>
												<span>de cada mes.</span>
											</div>
										</b-form-group>
									</b-col>
								</b-row>
							</b-card>

							<b-card>
								<h2 class="mb-2">Información de contacto</h2>

								<b-row>
									<b-col xl="8">
										<b-form-group label-for="address">
											<template #label>
												<span>Dirección</span>
												<span class="required-asterisk">(*)</span>
											</template>
											<validation-provider rules="required" v-slot="{ errors, valid }">
												<b-form-input
													id="address"
													v-model="userObject.address"
													:state="errors[0] ? false : valid ? true : null"
													:maxLength="50"
													:placeholder="'Ingresar una dirección...'"
												/>
												<span class="error-span">{{ errors[0] }}</span>
											</validation-provider>
										</b-form-group>
									</b-col>

									<b-col xl="4">
										<b-form-group label-for="phone_number">
											<template #label>
												<span>Teléfono</span>
												<span class="required-asterisk">(*)</span>
											</template>
											<validation-provider rules="tel" v-slot="{ errors }">
												<b-form-input
													id="phone_number"
													v-model="userObject.phone_number"
													v-mask="'#########'"
													:class="
														errors[0]
															? 'is-invalid'
															: !errors[0] && userObject.phone_number
															? 'is-valid'
															: ''
													"
													:maxLength="50"
													:placeholder="'Ingresar un número de teléfono...'"
												/>
												<span class="error-span">{{ errors[0] }}</span>
											</validation-provider>
										</b-form-group>
									</b-col>
								</b-row>
							</b-card>
						</b-col>
					</b-row>
					<b-row class="w-100">
						<b-col cols="12" md="12">
							<b-card>
								<div class="d-flex justify-content-between align-items-center">
									<h2 class="mb-2">Información bancaria</h2>
									<b-button variant="success" class="btn btn-icon btn-primary" @click="addRow">
										<feather-icon class="text-white cursor-pointer" icon="PlusIcon" size="15" />
									</b-button>
								</div>

								<b-row v-for="(bank, index) in userObject.bank_accounts" :key="index">
									<b-col xl="3">
										<b-form-group label-for="bank">
											<template #label>
												<span>Banco</span>
												<span class="required-asterisk">(*)</span>
											</template>
											<validation-provider rules="required" v-slot="{ errors, valid }">
												<v-select
													id="bank"
													v-model="bank.bank_id"
													:options="configurations.banks"
													:clearable="false"
													class="w-100"
													label="name"
													:reduce="(option) => option.id"
													:placeholder="'Seleccionar una entidad bancaria...'"
												/>
												<span class="error-span">{{ errors[0] }}</span>
											</validation-provider>
										</b-form-group>
									</b-col>

									<b-col xl="4">
										<b-form-group label="Numero de cuenta bancaria" label-for="account_number">
											<template #label v-if="bank.bank_id === 1">
												<span>Numero de cuenta bancaria</span>
												<span class="required-asterisk">(*)</span>
											</template>
											<validation-provider
												:rules="bank.bank_id !== 1 ? '' : 'required'"
												v-slot="{ errors, valid }"
											>
												<b-form-input
													type="number"
													id="account_number"
													v-model="bank.account_number"
													:maxLength="50"
													:placeholder="'Ingresar cuenta bancaria...'"
												/>
												<span class="error-span">{{ errors[0] }}</span>
											</validation-provider>
										</b-form-group>
									</b-col>

									<b-col xl="4">
										<b-form-group label="CCI" label-for="cci">
											<template #label v-if="bank.bank_id !== 1">
												<span>CCI</span>
												<span class="required-asterisk">(*)</span>
											</template>
											<validation-provider
												:rules="bank.bank_id === 1 ? '' : 'required'"
												v-slot="{ errors, valid }"
											>
												<b-form-input
													type="number"
													id="cci"
													v-model="bank.cci"
													:maxLength="10"
													:placeholder="'Ingresar cuenta interbancaria...'"
												/>
												<span class="error-span">{{ errors[0] }}</span>
											</validation-provider>
										</b-form-group>
									</b-col>

									<b-col xl="1">
										<div
											class="d-flex align-items-center justify-content-end h-100"
											:class="userObject.bank_accounts.length === 1 ? 'disabled-btn' : ''"
										>
											<b-button
												variant="danger"
												class="btn btn-icon btn-primary"
												@click="deleteRow(index)"
											>
												<feather-icon
													size="15"
													icon="XIcon"
													class="text-white cursor-pointer"
												/>
											</b-button>
										</div>
									</b-col>
								</b-row>
							</b-card>
						</b-col>
					</b-row>
				</b-form>
			</validation-observer>

			<div class="d-flex align-items-center justify-content-center w-100">
				<b-button variant="primary" v-if="showForm" @click="onSubmit">Guardar</b-button>
			</div>
		</div>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal"
import { Money } from "v-money"
import usersService from "@/services/users.service"
import store from "@/store"

export default {
	components: { Money },
	mixins: [modalMixin],
	props: {
		show: Boolean,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
			showForm: false,
			userObject: {
				personal_id: null,
				iduser: this.$route.params.user_id,
				avatar: "",
				document_type_id: 1,
				document_number: null,
				dob: null,
				first_name: null,
				middle_name: null,
				last_name: null,
				second_last_name: null,
				address: null,
				phone_number: null,
				email: null,
				password: null,
				roles: [],
				status: 1,
				file_name: null,
				original_avatar: null,
				privilege_id: null,
				country_id: 178,
				gender_id: null,
				date_joined: null,
				salary: null,
				pay_day: null,
				bank_accounts: [
					{
						bank_id: null,
						account_number: null,
						cci: null,
					},
				],
			},
			days: [
				1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
				29, 30,
			],
			pay_day: 5,
			configurations: {},
		}
	},
	async created() {
		await this.getConfigurations()
	},
	computed: {
		isEditRoute() {
			return this.$route.path.includes("/users-route/editar")
		},
		maxDate() {
			const today = new Date()
			// Restamos 15 años a la fecha actual
			const maxDate = new Date(today.setFullYear(today.getFullYear() - 15))
			// Formateamos la fecha en el formato "YYYY-MM-DD"
			return maxDate.toISOString().split("T")[0]
		},
	},
	methods: {
		onHide(bvEvent) {
			// Evitamos que el modal se cierre automáticamente
			bvEvent.preventDefault()
		},
		handleHidden() {
			if (this.showForm) {
				this.showForm = false
				return
			}
			this.isActive = false
			this.isLoading = false

			this.$emit("closing")

			// if (this.$route.path.includes("/users-route/editar")) {
			// 	this.isActive = false
			// } else if (this.currentUser.fields_completed === 0) {
			// 	this.isActive = true
			// }
		},
		addRow() {
			this.userObject.bank_accounts.push({
				bank_id: null,
				account_number: null,
				cci: null,
			})
		},
		deleteRow(index) {
			this.userObject.bank_accounts.splice(index, 1)
		},

		checkFieldsCompleted() {
			if (this.$route.path.includes("/users-route/editar")) {
				this.isActive = false
			} else if (this.currentUser.fields_completed === 0) {
				this.isActive = true
			}
		},
		async getClientByDni() {
			if (this.userObject.document_number.length < 8) {
				this.showToast(
					"warning",
					"top-right",
					"Advertencia",
					"WarningIcon",
					"El número de DNI debe tener 8 dígitos"
				)
			}

			this.isPreloading()

			try {
				const data = await usersService.getClientByDni(this.userObject.document_number)
				if (data.data.success === false) {
					this.showToast("warning", "top-right", "Advertencia", "WarningIcon", data.data.message)
					return
				} else {
					const names = data.data.data.nombres.split(" ")

					this.userObject.first_name = names[0]
					this.userObject.middle_name = names[1]
					this.userObject.last_name = data.data.data.apellido_paterno
					this.userObject.second_last_name = data.data.data.apellido_materno
				}
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
		async getUserById() {
			this.isPreloading()

			try {
				const res = await usersService.getUserById(this.currentUser.id)
				this.userObject = res.data
				this.userObject.salary = this.userObject.salary != null ? this.userObject.salary : 0
				if (this.userObject.country_id === null) {
					this.userObject.country_id = 178
				}

				if (this.userObject.bank_accounts.length === 0) {
					this.userObject.bank_accounts.push({
						bank_id: null,
						account_number: null,
						cci: null,
					})
				}
				const result = this.userObject.roles.filter((rol) => rol != 1)
				if (result.length > 0) {
					this.rol = 2
				} else {
					this.rol = 1
				}
				this.userObject = res.data
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
		async getConfigurations() {
			this.isPreloading()

			try {
				const { data } = await usersService.getConfigurations()
				this.configurations = data
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
		async onSubmit() {
			if (!(await this.$refs.form.validate())) return
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return

			this.isPreloading()
			const banks = this.userObject.bank_accounts.map((b) => {
				return {
					bank_id: b.bank_id,
					account_number: b.account_number,
					cci: b.cci,
				}
			})

			const body = {
				personal_id: this.userObject.personal_id,
				document_type_id: 1,
				document_number: this.userObject.document_number,
				first_name: this.userObject.first_name,
				middle_name: this.userObject.middle_name,
				last_name: this.userObject.last_name,
				second_last_name: this.userObject.second_last_name,
				dob: this.userObject.dob,
				gender_id: this.userObject.gender_id,
				country_id: this.userObject.country_id,
				address: this.userObject.address,
				phone_number: this.userObject.phone_number,
				file_name: this.userObject.file_name ? this.userObject.file_name : null,
				file_image: this.userObject.avatar,
				access: {
					email: this.userObject.email,
					password: this.userObject.password,
					roles: this.$route.params.user_id ? this.userObject.roles : this.userObject.roles,
					privilege_id: this.userObject.privilege_id ? this.userObject.privilege_id : null,
				},
				work_info: {
					date_joined: this.userObject.date_joined,
					salary: this.userObject.salary,
					pay_day: this.pay_day,
				},
				bank_accounts: banks,
				status: this.userObject.status,
			}

			try {
				const { data } = await usersService.updateUser(this.userObject.id, body)

				this.handleHidden()
				this.showSuccessToast(data.message)
				let userData = JSON.parse(localStorage.getItem("vuex"))
				userData.user.fields_completed = 1
				userData.user.name = this.userObject.first_name
				userData.user.last_name = this.userObject.last_name
				localStorage.setItem("vuex", JSON.stringify(userData))
				store.commit("auth/SET_CURRENT_USER", userData)
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},

	async mounted() {
		this.toggleModal("modal-complete-user")
		this.checkFieldsCompleted()
		if (this.currentUser.id) {
			await this.getUserById()
		}
	},

	watch: {
		show(val) {
			if (val) {
				this.checkFieldsCompleted()
			}
		},
		"userObject.document_number"(val) {
			if (val) {
				if (this.userObject.document_number.length === 8 && this.userObject.personal_id === null) {
					this.getClientByDni()
				}
				if (this.userObject.document_number.length < 8) {
					this.userObject.first_name = null
					this.userObject.middle_name = null
					this.userObject.last_name = null
					this.userObject.second_last_name = null
				}
			}
		},
	},
}
</script>

<style scoped>
>>> .modal-content .close {
	display: none !important;
	pointer-events: none !important;
}

>>> .modal-content .modal-header h5 {
	color: red !important;
}

.error-span {
	color: red;
	font-size: 0.85rem;
	margin-top: 0.2rem;
}

.required-asterisk {
	color: red;
}

.form-group input {
	color: #6e6b7b !important;
}
</style>

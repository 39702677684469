import axios from "@/axios"

const url = "/api/users/"
const url2 = "/api/commons/"

class UsersService {
	async registerUser(body) {
		try {
			const data = await axios.post(`${url}`, body)
			return data
		} catch (error) {
			throw error
		}
	}
	async updateUser(id, body) {
		try {
			const data = await axios.put(`${url}${id}`, body)
			return data
		} catch (error) {
			throw error
		}
	}
	async getConfigurations() {
		const { data } = await axios.get(`${url2}personals/get-configurations/`)
		return data
	}
	async getUserById(id) {
		try {
			const { data } = await axios.get(`${url}${id}`)
			return data
		} catch (error) {
			throw error
		}
	}
	async getClientByDni(dni) {
		try {
			const { data } = await axios.get(`${url2}personals/search-dni/${dni}`)
			return data
		} catch (error) {
			throw error
		}
	}
}
export default new UsersService()

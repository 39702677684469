export default [
	{
		path: "/videoteca",
		name: "videolibrary-specimens-sr",
		component: () =>
			import(
				/* webpackChunkName: "videolibrary-specimens-sr" */ "@/views/amg/videolibrary/VideoSpecimenList.vue"
			),
		props: { origin: "main" },
		meta: {
			layout: "vertical",
			pageTitle: "Videoteca de ejemplares",
			breadcrumb: [],
			roles: [1, 4, 6],
		},
	},
	{
		path: "/videoteca/*",
		redirect: "/videoteca/ejemplares",
	},
]

import { ValidationProvider, ValidationObserver, extend } from "vee-validate"
import { required, email, max, min } from "vee-validate/dist/rules"

extend("required", {
	...required,
	message: "Este campo es obligatorio",
})
extend("email", {
	...email,
	message: "Ingresa un email válido",
})
extend("max", {
	...max,
	message: "Excedió el número de caracteres",
})
extend("min", {
	...min,
	message: "Este campo requiere 11 digitos",
})
extend("tel", {
	validate: (value) => value.length == 9,
	message: "Este campo requiere 9 digitos o menos",
})
extend("dni", {
	validate: (value) => value.length == 8,
	message: "Este campo requiere 8 digitos",
})
extend("zero", {
	validate: function (value) {
		if (value > 0) {
			return true
		} else {
			return false
		}
	},
	message: "Ingresa un valor mayor a 0",
})

extend("vselect", {
	validate: (value) => value.id,
	message: "Este campo es obligatorio",
})
extend("vselect-upload-video", {
	validate: (value) => value.item,
	message: "Este campo es obligatorio",
})

extend("v-true", {
	validate: (value) => value == true,
	message: "Este campo debe estar marcado",
})

import axios from "@/axios"
import router from "@/router"

export const updateCurrentUser = ({ commit }, data) => {
	commit("SET_CURRENT_USER", data)
}

export const login = async ({ commit }, credentials) => {
	try {
		const { data } = await axios.post("/api/login", credentials)
		const dataUser = { ...data, user: { ...data.user, user_id: data.user.id } }
		if (dataUser.user.notification != null) {
			commit("SET_BIRTHDAY", true)
		}
		localStorage.setItem("vuex", JSON.stringify(dataUser))
		commit("SET_CURRENT_USER", dataUser)
		router.push({ name: "menu" })
	} catch (error) {
		commit("SET_CURRENT_USER", { user: null, token: null })
		throw error
	}
}

export const logout = async ({ commit }) => {
	try {
		await axios.post("/api/logout")
	} catch (error) {
		throw error
	} finally {
		commit("SET_CURRENT_USER", { user: null, token: null })
		localStorage.removeItem("vuex")
		router.push({ name: "login" })
	}
}

export const checkAuth = async ({ commit }) => {
	try {
		await axios.post("/api/verify-auth")
		// localStorage.setItem("vuex", JSON.stringify(data));
		// commit("SET_CURRENT_USER", data);
	} catch (error) {
		const resp = error.response.status
		if (resp == 401) {
			commit("SET_CURRENT_USER", { user: null, token: null })
			localStorage.removeItem("vuex")
			router.push({ name: "login" })
			throw error
		}
	}
}
